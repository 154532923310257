<template>
    <zw-sidebar @shown="shown" :title="$t('common.title.table_configuration')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading" style="/*zoom: 1.332;*/">
                <b-table-simple striped
                                class="columns-table zoomed-table"
                >
                    <b-thead head-variant="info">
                        <b-th></b-th>
                        <b-th>{{ $t('common.label.columns.column') }}</b-th>
                        <b-th>
                            {{ $t('common.label.columns.enabled') }}
                            <b-form-checkbox v-model="selectAll" size="lg"></b-form-checkbox>
                        </b-th>
                    </b-thead>
                    <draggable :class="{[`cursor-grabbing`]: dragging === true}"
                               v-model="columnsList"
                               group="columns"
                               @start="dragging = true"
                               @end="dragging = false"
                               tag="tbody"
                               handle=".handle"
                    >
                        <b-tr v-for="column in columnsList" :key="column">
                            <b-td>
                                <div class="handle">
                                    <font-awesome-icon icon="grip-horizontal"></font-awesome-icon>
                                </div>
                            </b-td>
                            <b-td><span v-for="group in columnGroups[column]">[{{ group }}]</span>{{
                                    $t(columnsLabels[column])
                                }}
                            </b-td>
                            <b-td>
                                <b-form-checkbox
                                    v-model="columns[column]"
                                    size="lg"
                                ></b-form-checkbox>
                            </b-td>
                        </b-tr>
                    </draggable>
                </b-table-simple>

                <b-row>
                    <b-col cols="12">
                        <b-button block @click="save" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import Draggable from "vuedraggable"
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    components: {
        Draggable
    },
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            columns: [],
            columnsList: [],
            dragging: false,
            columnsLabels: {},
            columnGroups: {},
            payload: {},
            callback: null,
            selectAll: false,
        }
    },
    methods: {
        shown() {
            this.columns = {}
            axios.post(window.apiUrl + '/table-columns', {
                table: this.payload.table,
                tableFormat: this.payload.tableFormat
            }, {'skip_loading': true})
                .then(result => {
                    this.columns = result.data.columns
                    this.columnsList = Object.keys(result.data.columns)
                    this.columnsLabels = result.data.labels
                    this.columnGroups = result.data.groups

                    this.loading = false
                }, () => {
                    this.loading = false
                })
        },
        save() {
            let ordering = {}
            let loop = 0
            this.columnsList.forEach((column, key) => {
                if (this.columns[column]) {
                    ordering[column] = loop
                    loop += 1
                }
            })

            let list = JSON.parse(JSON.stringify(this.columnsList));

            list.sort((a, b) => {
                return this.$t(this.columnsLabels[a]).localeCompare(this.$t(this.columnsLabels[b]))
            })

            list.forEach((column, key) => {
                if (!this.columns[column]) {
                    ordering[column] = loop
                    loop += 1
                }
            })

            axios.post(window.apiUrl + '/save-table-columns', {
                table: this.payload.table,
                columns: this.columns,
                ordering: ordering,
            }, {'skip_loading': true})
                .then(result => {
                    this.commonAfterSave(result)
                }, () => {
                    this.loading = false
                })
        },
    },
    watch: {
        selectAll: function (val) {
            this.columnsList.forEach(key => {
                this.columns[key] = val
            })
        },
    }
}
</script>

<style>
.columns-table td {
    padding-top: 2px;
    padding-bottom: 2px;
}

.sortable-ghost {
    background-color: lightblue !important;
}
</style>